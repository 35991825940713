fieldset {
    padding:0 8px 8px 8px; 
    width: 355px;
    min-height: 65px;
    margin:auto;
    margin-bottom:14px;
    border: 1px solid #E6E7E7;
    border-radius:5px;
    text-align: left;
}

legend{
    height:20px;
    font-size:1.1em;
    text-align:left;
    color:#79808A;
    padding-left:4px;
    letter-spacing:1px;
}

.rack-legend{
    cursor: pointer; 
    background:url('../../public/images/shuffle.svg') no-repeat left; 
    padding-left:24px;
}

#rack {
    text-align:left;
}