.tile {
    display: inline-block;
    border: 0;
    border-radius: 5px;
    height: 38px;
    line-height: 38px;
    width: 38px;
    font-size: 26px;
    font-weight: bold;
    text-align: center;
    margin: 2px;
    padding: 0px 2px 2px 2px;
    color:#fff;
    /*caret-color: transparent;*/
    font-family: "Helvetica Neue", Helvetica, Arial;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
    -webkit-appearance: none;
}

.gray {
    background-color: #7a8089;
}

.green {
    background-color: #478A58;
}

.yellow {
    background-color: #d3bd5b;
}

.orange {
    background-color: #F0A81F;
}

.red {
    background-color: #F0524D;
}

.blank {
    border: 1px solid #999;
    background-color: #fff;
    color:#999;
}

.subscript {
    vertical-align: sub;
    font-size:.5em;
}
