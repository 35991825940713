button {
    font-weight:bold;
    color: #ffffff;
    padding: 10px 16px 10px 16px;
    border:none;
    margin-bottom:10px;
    font-size:1.1em;
    letter-spacing: 1px;
    border-radius:5px;
    cursor:pointer;
    background-color: #478A58; 
}

button:hover {
    background-color: #33643f; 
}

div {
    color:#0B4e65;
}

.shareBtn {
    background:url('../../public/images/share.svg') no-repeat right;
    font-weight:bold;
    color: #ffffff;
    padding: 10px 48px 10px 16px;
    border:none;
    margin:5px;
    margin-bottom:10px;
    font-size:1.1em;
    letter-spacing: 1px;
    border-radius:5px;
    cursor:pointer;
    background-color: #478A58; 
}